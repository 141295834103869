<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input style="width: 200px!important;"
          class="w120 mb10 mr10"
          placeholder="分类名称"
          v-model="filterName"
                  clearable
        />
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button class="ma" type="primary" @click="centerDialogVisible = true"
          >+ 添加分类</el-button
        >
      </div>
    </div>
    <commonTable
      :tableData="tableData"
      @handleSelectionChange="handleSelectionChange"
      :total="total"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      :currentPage="currentPage"
      :loading="loading"
    >
      <template v-slot:table>
         <el-table-column type="selection" />
        <el-table-column prop="name" align="center" label="分类名称" />
        <el-table-column prop="" align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，分类排序越靠前" placement="right-start">
              <el-input v-model="scope.row.orderBy" min="0" @input="scope.row.orderBy=scope.row.orderBy.replace(/^0(0+)|[^\d]+/g,'')" placeholder="数值越大，分类排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="open(scope.row)"
              >编辑</el-button
            >
            <customPopconfirm
              class="ma ml10 mb10"
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="del(scope.row.articleTypeId)"
              title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="添加分类"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
      >
        <el-form-item label="分类名称" prop="title">
          <el-input clearable v-model="ruleForm.title" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="title">
          <el-input clearable v-model="ruleForm.sort" min="0" @input="ruleForm.sort=ruleForm.sort.replace(/^0(0+)|[^\d]+/g,'')"  maxlength="11" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {addArticleType,selectArticleTypePageList,upArticleType,delArticleType} from "@/api/content";

export default {
  name: "productList",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
        type: Boolean,
        default: false,
    },
  },
  data() {
    return {
      centerDialogVisible: false,
      ruleForm: {
        title: "",
        sort: "",
      },
      rules: {
        title: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        sort: [
          { required: true, message: "请输入序号", trigger: "blur" },
        ],
      },

      userInfo: this.$store.state.loginRoot.userInfo,
      options: [{ label: "全部", value: 1 }],
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading:false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      upDate:1,
      articleTypeId:''
    };
  },
  mounted(){
    this.queryPage();
  },
  methods: {
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        type:0,
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectArticleTypePageList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    //分类弹窗
    open(row) {
      this.ruleForm.title = row.name;
      this.ruleForm.sort = row.orderBy;
      this.articleTypeId = row.articleTypeId
      this.upDate = 2
      this.centerDialogVisible = true
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    //删除弹窗
    async del(val) {
      try {
        await delArticleType({ articleTypeId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    async saveSort(row){
      if(row && row.sort != ""){
        let ajax = {
          orderBy:row.orderBy,
          articleTypeId:row.articleTypeId
        }
        const result = await upArticleType(ajax);
        this.$message({
          type: "success",
          message: "保存成功",
        });
        let data = {
          name:this.filterName
        };
        await this.queryPage(data);
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    determine(){
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              name:this.ruleForm.title,
              orderBy:this.ruleForm.sort,
              type:0
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addArticleType(data);
            }else {
              data.articleTypeId = this.articleTypeId
              await upArticleType(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    filterNames(){
      this.currentPage = 1;
      let data = {
        name:this.filterName
      };
      this.queryPage(data);
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        sort: "",
      }),
          this.centerDialogVisible = false;
    },
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input
            style="width: 200px!important;"
            class="w120 mb10 mr10"
            placeholder="名称"
            v-model="filterName"
            clearable
        />
        <el-select
            class="mr10"
            v-model="filterType"
            clearable
        >
          <el-option
              v-for="(item) in typeList"
              :key="item.articleTypeId"
              :label="item.name"
              :value="item.articleTypeId"
          />
        </el-select>
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button
            class="mr10"
            type="primary"
            @click="
                centerDialogVisible = true;
                bdetermine = true;
                editUpdate = false
              "
        >+ 发布文章</el-button>
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="selection" width="50"/>
        <el-table-column prop="name" align="center" label="名称" show-overflow-tooltip/>
        <el-table-column prop="classType" align="center" label="分类" show-overflow-tooltip/>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="right-start">
              <el-input v-model="scope.row.orderBy" min="0" @input="scope.row.orderBy=scope.row.orderBy.replace(/^0(0+)|[^\d]+/g,'')" placeholder="数值越大，排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="uploadTime" align="center" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
                @change="saveStatus(scope.row)"
                v-model="scope.row.status"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="uploadTime" align="center" :render-header="renderHeader3" label="文章置顶" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
                @change="saveIfPass(scope.row)"
                v-model="scope.row.ifTop"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
<!--            <el-button-->
<!--                type="text"-->
<!--                @click="-->
<!--                edit(scope.row, false);-->
<!--                bEdit = true;-->
<!--              "-->
<!--            >详情</el-button-->
<!--            >-->
            <el-button
                type="text"
                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
            >编辑</el-button>
            <customPopconfirm
                confirm-button-text="确定"
                k              cancel-button-text="取消"
                @confirm="del(scope.row.workId)"
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="发布文章"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
      >
        <el-form-item label="文章名称" prop="title">
          <el-input clearable v-model="ruleForm.title" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="分类" prop="type">
          <el-select
              clearable
              v-model="ruleForm.type"
              style="width: 100%"
          >
            <el-option
                v-for="(item) in typeList"
                :key="item.articleTypeId"
                :label="item.name"
                :value="item.articleTypeId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input clearable v-model="ruleForm.sort"  min="0" @input="ruleForm.sort=ruleForm.sort.replace(/^0(0+)|[^\d]+/g,'')" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="上传类型" prop="uploadType">
          <el-radio-group v-model="ruleForm.uploadType">
            <el-radio :label="0">图文</el-radio>
            <el-radio :label="1">视频</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="ruleForm.uploadType==1?'封面':'图片'" prop="coverImg">
          <el-upload :action="$store.state.uploadingUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :before-upload="beforeAvatarUploadImg"
                     :file-list="fileList"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              <div slot="tip" class="el-upload__tip">
                图片上传比例为5:3，支持格式png、jpg、jpeg，大小不超过1M
              </div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频内容" prop="videoUrl" v-if="ruleForm.uploadType==1">
          <el-upload
              class="avatar-uploader"
              :action="$store.state.uploadingUrl"
              :show-file-list="false"
              accept=".mp4"
              :on-progress="handProgress"
              :on-success="handleAvatarSuccessVideo"
              :before-upload="beforeAvatarUploadVideo"
          >
            <video
                v-if="ruleForm.videoUrl"
                :src="ruleForm.videoUrl"
                width="100%"
                height="100%"
                controls
            >
              <source src="movie.mp4" type="video/mp4" />
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过20M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="状态" prop="isState">
          <el-radio-group v-model="ruleForm.isState">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="文章置顶" prop="ifTop">
          <el-switch
              v-model="ruleForm.ifTop"
              active-color="#51CDCB"
              inactive-color="#dadde5">
          </el-switch>
        </el-form-item>
        <el-form-item label="简介" prop="desc">
          <el-input type="textarea" v-model="ruleForm.desc" maxlength="500" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="details">
          <!-- //富文本 -->
          <quill-editor
              @ready="ready($event)"
              style="height: 100%"
              v-model="ruleForm.details"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange($event)"
          />
          <span class="wordNumber">{{TiLength}}/8000</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { lineHeightStyle } from "@/utils/lineHeight.js";
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写内容'
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {addWork,upWork,delWork,selectWorkList,selectArticleTypePageList,upWorkTop} from "@/api/content";

export default {
  name:'homeMenu',
  data() {
    return {
      TiLength:0,
      editorOption: quillConfig,
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      isUpdate:true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        title: "",
        sort: "",
        isState: 0,
        skipLink: 0,
        link:'',
        coverImg:'',
        appId:'',
        type:'',
        ifTop:false,
        details: "",
        desc:'',
        uploadType:0,
        videoUrl:""
      },
      rules: {
        title: [{ required: true, message: "请输入文章名称", trigger: "blur" }],
        coverImg: [{ required: true, message: "请上传图片" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        details: [{ required: true, message: "请输入内容", trigger: "blur" }],
        desc: [{ required: true, message: "请输入简介", trigger: "blur" }],
        ifTop: [{ required: true, message: "请选择是否置顶"}],
        isState: [{ required: true, message: "请选择状态"}],
        // skipLink: [{ required: true, message: "请选择跳转链接"}],
        type: [{ required: true, message: "请选择分类"}],
      },
      jurisdictionA:'',
      jurisdictionB:'',
      typeList:[],
      workId:'',

      filterName:'',
      filterType:'',
      ossUrl: this.$store.state.ossUrl,
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  computed: {
    ...mapState(['routerArr']),
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  watch:{
    routerArr(val){
      console.log(val)
      this.jurisdictionA = val.includes('080')
      this.jurisdictionB = val.includes('081')
      if(this.jurisdictionA || this.jurisdictionB){
        this.queryPage()
      }
    }
  },
  mounted(){
    this.getTypeList()
  },
  methods: {
    renderHeader3(h,{column}) {
      return h('div',[
        h('span', column.label),
        h('el-tooltip',{undefined,
              props:{undefined,
                effect:'dark',
                placement:'top',
                content:''//不需要换行就直接写在这里
              },
            },
            [//新加一个div才能换行,不需要换行可以直接在上面加content
              h('div',
                  {
                    slot:"content",
                  },
                  ['置顶优先级最高']
              ),
              h('i', {undefined,
                class: 'el-icon-question',
                style: "color:#474747;margin-left:5px;cursor:pointer;"
              })],
        )
      ]);
    },
    ready() {
      Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
    },
    onEditorChange(e){
      // e.quill.deleteText(2000,0);
      if(this.ruleForm.details==''){
        this.TiLength=0
      }else{
        this.TiLength=e.quill.getLength()-1
      }
    },
    async getTypeList(){
      let data = {
        pageSize: 999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
        type: 0
      };
      try {
        const result = await selectArticleTypePageList(data);
        const { total, list } = result.data.pageInfo;
        this.typeList = list;
        this.queryPage();
      } catch (error) {
        this.loading = false;
      }
    },
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        type:0,
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectWorkList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          if (list[i].status == 0) {
            list[i].status = true
          }else {
            list[i].status = false
          }
          list[i].ifTop = list[i].ifTop==0?true:false;
          for (let j in this.typeList) {
            if (list[i].classType == this.typeList[j].articleTypeId) {
              list[i].classType = this.typeList[j].name
            }
          }
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.ruleForm.coverImg = "";
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        name:this.filterName,
        classType:this.filterType
      };
      this.queryPage(data);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        name:this.filterName,
        classType:this.filterType
      };
      this.queryPage(data);
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm.title = data.name;
      this.ruleForm.sort = data.orderBy;
      this.ruleForm.isState = data.status?0:1;
      this.ruleForm.ifTop = data.ifTop
      this.ruleForm.uploadType = data.workType;
      this.ruleForm.videoUrl = data.url;
      // this.ruleForm.appId = data.appId;
      this.ruleForm.type = data.classType
      this.ruleForm.details = data.remark
      this.ruleForm.coverImg = data.coverUrl;
      this.fileList = [{ url: data.coverUrl, uid: 1 }];
      this.ruleForm.desc = data.content
      this.bdetermine = b;
      this.bEdit = b;
      this.workId = data.workId
    },
    /**@method 添加视频 */
    determine() {
      if (this.ruleForm.uploadType == 1) {
        if (this.ruleForm.videoUrl){}else{
          this.$message.error('请上传视频')
          return
        }
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              name:this.ruleForm.title,
              orderBy:this.ruleForm.sort,
              status:this.ruleForm.isState,
              // jumpType:this.ruleForm.skipLink,
              // url:this.ruleForm.link,
              classType:this.ruleForm.type,
              ifTop:this.ruleForm.ifTop?0:1,
              remark:this.ruleForm.details,
              content:this.ruleForm.desc,
              coverUrl:this.ruleForm.coverImg,
              type:0,
              workType:this.ruleForm.uploadType
            };
            if (this.ruleForm.uploadType == 1) {
              data.url = this.ruleForm.videoUrl
            }
            if (this.ruleForm.skipLink == 2) {
              data.appId = this.ruleForm.appId
            }
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              var patrn=/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;
              if(patrn.exec(data.classType)){
                for (let i in this.typeList) {
                  if (data.classType == this.typeList[i].name) {
                    data.classType = this.typeList[i].articleTypeId
                  }
                }
              }
              data.workId = this.workId
              await upWork(data);
            } else {
              await addWork(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delWork({ workId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        sort: "",
        isState: 0,
        skipLink: 0,
        link:'',
        coverImg:'',
        appId:'',
        type:'',
        ifTop:true,
        details:'',
        desc:'',
        uploadType:0,
        videoUrl: ''
      }),
          this.fileList = []
          this.centerDialogVisible = false;
    },
    async saveSort(row){
      if(row && row.sort != ""){
        let ajax = {
          orderBy:row.orderBy,
          workId:row.workId
        }
        const result = await upWork(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        let data = {
          name:this.filterName,
          classType:this.filterType
        };
        await this.queryPage(data);
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    async saveStatus(row){
      if(row){
        let ajax = {
          workId:row.workId,
          status:row.status?0:1
        }
        const result = await upWork(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        let data = {
          name:this.filterName,
          classType:this.filterType
        };
        await this.queryPage(data);
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    async saveIfPass(row){
      if(row){
        let ajax = {
          workId:row.workId,
          ifTop:row.ifTop?0:1,
          associationId: localStorage.getItem("associationId"),
        }
        const result = await upWorkTop(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        let data = {
          name:this.filterName,
          classType:this.filterType
        };
        await this.queryPage(data);
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    beforeAvatarUploadImg(file){
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return isLt2M;
    },
    filterNames(){
      this.currentPage = 1;
      let data = {
        name:this.filterName,
        classType:this.filterType
      };
      this.queryPage(data);
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if(this.isUpdate){
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      this.ruleForm.videoUrl = `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${res.data}`;
    },
    beforeAvatarUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 20MB!");
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>





